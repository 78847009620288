
import { defineComponent } from 'vue';
import Button from '@/components/controls/Button';
import Panel from '@/components/layout/Panel.vue';
import TextField from '@/components/controls/TextField.vue';
import TextArea from '@/components/controls/TextArea.vue';
import ViewMixin from '@/mixins/ViewMixin';

export default defineComponent({
    name: 'UiElements',
    components: {
        Button,
        Panel,
        TextArea,
        TextField,
    },
    mixins: [ViewMixin],
    data: () => ({
        requiredField: '',
        errorTest: '',
        description: '',
    }),
    computed: {
        errors(): string[] {
            return [
                'error 1',
                'error 2',
                'error 3',
            ];
        },
    },
    created(): void {
        this.setPageTitle(['InGame', 'Items']);
    },
});
